import { Tooltip, CircularProgress, IconButton } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import {
  CustomDataGrid,
  RegularButton,
  acquireAccessToken,
  parseJwt,
  CenteredDiv,
  ErrorMessage
} from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../../index.js'
import PageNavbar from '../controls/PageNavbar'
import InventoryApi from '../../api/InventoryApi'
import CartApi from '../../api/CartApi'

export default function PartsPage(props) {
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')
  const [cart, setCart] = useState([])

  const {
    data: parts,
    isLoading: partsLoading,
    error: partsError
  } = useQuery({
    queryKey: ['parts'],
    queryFn: async () => {
      let parts = await InventoryApi.getTrackedParts(instance, accounts)
      return parts
    }
  })

  const {
    data: cartItems,
    isLoading: cartLoading,
    error: cartError,
    refetch: refetchCart
  } = useQuery({
    queryKey: ['cart'],
    queryFn: async () => {
      let cartItems = await CartApi.getCart(instance, accounts)
      return cartItems
    }
  })

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken])

  const removeFromCart = async id => {
    await CartApi.deleteCartItem(instance, accounts, id)
    refetchCart()
  }

  useEffect(() => {
    const lookupPart = partNumber => {
      let foundPart = { tiPartNumber: '', description: '' }
      parts.forEach(part => {
        if (part.tiPartNumber === partNumber) {
          foundPart = part
        }
      })
      return foundPart
    }
    if (cartItems && parts) {
      let newCart = cartItems.map((c, i) => {
        let cartItemId = c.id
        let newItem = {
          ...c,
          ...lookupPart(c.tiPartNumber),
          key: cartItemId
        }
        newItem.id = cartItemId

        return newItem
      })
      console.log('setCart()')
      setCart(newCart)
    }
  }, [parts, cartItems])

  const purchase = async () => {
    await CartApi.purchase(instance, accounts)
  }

  const saveCartItem = async item => {
    console.log(JSON.stringify(item))
    await CartApi.addCartItems(instance, accounts, [item])
    refetchCart()
  }

  function getUnitPrice(cartItem) {
    let qty = cartItem.requestedQty
    console.log(qty)
    if (!cartItem.pricing || cartItem.pricing.count === 0) return 1
    let priceBreaks = cartItem.pricing[0].priceBreaks
    console.log(JSON.stringify(priceBreaks))
    if (priceBreaks) {
      let priceBreak = {}
      for (let i = 0; i < priceBreaks.length; i++) {
        if (qty >= priceBreaks[i].priceBreakQuantity) {
          priceBreak = priceBreaks[i]
        }
      }
      console.log(JSON.stringify(priceBreak))
      return priceBreak.price
    }
    return 1
  }

  function getTotalPrice(cartItem) {
    let qty = cartItem.requestedQty
    let price = getUnitPrice(cartItem)
    return qty * price
  }

  function getSubTotal() {
    let subTotal = 0
    //console.log(JSON.stringify(cart))
    for (let i = 0; i < cart.length; i++) {
      let cartItem = cart[i]
      //console.log(JSON.stringify(cartItem))
      let cost = getTotalPrice(cartItem)
      subTotal += cost
    }
    return subTotal
  }

  if (partsLoading || cartLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (partsError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={partsError} />
      </CenteredDiv>
    )
  }

  if (cartError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={cartError} />
      </CenteredDiv>
    )
  }

  const columns = [
    {
      headerName: 'Part Number',
      field: 'tiPartNumber',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2
    },
    {
      headerName: 'Qty',
      field: 'quantity',
      type: 'number',
      flex: 0.5
    },
    {
      headerName: 'Min Qty',
      field: 'minimumOrderQuantity',
      type: 'number',
      flex: 0.5
    },
    {
      headerName: 'Max Qty',
      field: 'limit',
      type: 'number',
      flex: 0.5
    },
    {
      headerName: 'Purchase Qty',
      field: 'requestedQty',
      type: 'number',
      editable: true,
      flex: 0.5,
      valueSetter: setValueQty,
      preProcessEditCellProps: params => {
        const hasError = params.props.value <= 0
        return { ...params.props, error: hasError }
      }
    },
    {
      headerName: 'Unit Price',
      field: 'x',
      type: 'number',
      flex: 1,
      renderCell: u => {
        return <div>{`$${getUnitPrice(u.row).toFixed(3)}`}</div>
      }
    },
    {
      headerName: 'Total',
      field: 'y',
      type: 'number',
      flex: 1,
      renderCell: u => {
        return <div>{`$${getTotalPrice(u.row).toFixed(3)}`}</div>
      }
    },
    {
      headerName: '',
      field: 'z',
      resizable: false,
      flex: 1,
      minWidth: '20px',
      renderCell: u => {
        return (
          <div>
            <Tooltip title='Remove part from cart' style={{ margin: '10px 15px 0px 60px' }}>
              <IconButton
                onClick={async e => {
                  await removeFromCart(u.row.id)
                }}
                color='primary'
                size='large'>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  function setValueQty(params) {
    console.log(params.value)
    let item = { ...params.row }
    item.requestedQty = params.value
    saveCartItem(item)
    return item
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <PageNavbar></PageNavbar>
          <div
            style={{
              width: '100%',
              overflow: 'auto',
              margin: '0px 40px 40px 40px'
            }}>
            {!cart || cart.length === 0 ? (
              <div>{'There are parts in the cart'}</div>
            ) : (
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <RegularButton
                    style={{ display: 'inline' }}
                    onClick={e => {
                      purchase()
                    }}>
                    {'Purchase'}
                  </RegularButton>
                  <div
                    style={{
                      fontSize: '20px',
                      display: 'inline-block',
                      marginLeft: 'auto',
                      marginRight: '50px'
                    }}>{`Total $${getSubTotal()}`}</div>
                </div>
                <div style={{ marginTop: 15 }}>
                  <CustomDataGrid
                    sx={{ overflowX: 'scroll' }}
                    rows={cart}
                    loading={partsLoading || cartLoading}
                    columns={[...columns]}
                    cursor='pointer'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
