import * as React from 'react'
import { Card, CardContent } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { PrimaryButton, SnackbarVariants, CustomDataGrid, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import SubscriptionApi from '../../api/SubscriptionApi'

export default function AddSubscriptionModal(props) {
  const { instance, accounts } = useMsal()
  const [isBusy, setIsBusy] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  function onClose() {
    props.refresh()
    props.onClose()
  }

  const addSubscription = async () => {
    console.log('addSubscription')
    var subscriptionRequests = props.parts.map(p => {
      return {
        id: p.id,
        tiPartNumber: p.tiPartNumber,
        requestedQty: 1,
        currency: 'USD',
        autoSubscribe: true,
        expiration: 30
      }
    })
    await SubscriptionApi.addInventorySubscriptions(instance, accounts, subscriptionRequests).catch(err => {
      console.log(err.toString())
      enqueueSnackbar(`Failed to add subscriptions`, SnackbarVariants.ERROR)
    })
  }

  useEffect(() => {
    if (props.parts) {
      let subs = props.parts.map((p, i) => {
        return {
          id: i,
          tiPartNumber: p.tiPartNumber,
          description: p.description,
          requestedQty: p.minimumOrderQuantity,
          currency: 'USD',
          autoSubscribe: true,
          expiration: 30
        }
      })
      setSubscriptions(subs)
    }
  }, [props.parts])

  const columns = [
    {
      headerName: 'Part Number',
      field: 'tiPartNumber',
      flex: 0.2
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 0.5
    },
    {
      headerName: 'Quantity',
      field: 'requestedQty',
      type: 'number',
      flex: 0.1,
      editable: true
    },
    {
      headerName: 'Expires in (# of Days)',
      field: 'expiration',
      type: 'number',
      flex: 0.2,
      editable: true
    }
  ]

  if (!props.parts) {
    return <div></div>
  }

  return (
    <SlideUpDialog
      maxWidth='xl'
      open={props.open}
      onClose={() => {
        if (isBusy === false) {
          onClose()
        }
      }}
      title={<h3 style={{ margin: 0 }}>{'Add Subscription'}</h3>}
      actions={
        <>
          <PrimaryButton
            style={{ marginRight: 15 }}
            disabled={false}
            onClick={async () => {
              setIsBusy(true)
              await addSubscription()
              setIsBusy(false)
              onClose()
            }}>
            &nbsp;{'Add Subscription'}
          </PrimaryButton>
        </>
      }>
      <DialogContent>
        <Card style={{ minWidth: 700 }}>
          <CardContent>
            <CustomDataGrid
              rows={subscriptions}
              columns={columns}
              cursor='pointer'
              experimentalFeatures={{ newEditingApi: true }}
            />
          </CardContent>
        </Card>
      </DialogContent>
    </SlideUpDialog>
  )
}
