import { CircularProgress } from '@mui/material'
import { NorthOutlined } from '@mui/icons-material'
import {
  CustomDataGrid,
  RegularButton,
  acquireAccessToken,
  parseJwt,
  CenteredDiv,
  ErrorMessage,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../../index.js'
import AddPartModal from '../modals/AddPartModal'
import ViewPartModal from '../modals/ViewPartModal'
import AddSubscriptionModal from '../modals/AddSubscriptionModal'
import PageNavbar from '../controls/PageNavbar'
import InventoryApi from '../../api/InventoryApi'
import CartApi from '../../api/CartApi'
import { useHistory } from 'react-router'
import { DEFAULT_PARTS_FILTER, filterParts } from '../Drawers/PartFilterDrawer'
import PartFilterDrawer from '../Drawers/PartFilterDrawer'

export default function PartsPage(props) {
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')
  const [addPartModalOpen, setAddPartModalOpen] = useState(false)
  const [viewPartModalOpen, setViewPartModalOpen] = useState(false)
  const [addSubscriptionModalOpen, setAddSubscriptionModalOpen] = useState(false)
  const [selectedPart, setSelectedPart] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [filter, setFilter] = useState(DEFAULT_PARTS_FILTER)
  const history = useHistory()

  const {
    data: parts,
    isLoading: partsLoading,
    error: partsError,
    refetch: refetchParts
  } = useQuery({
    queryKey: ['parts'],
    queryFn: async () => {
      let parts = await InventoryApi.getTrackedParts(instance, accounts)
      return parts
    }
  })

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      //console.log('resetting token')
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken])

  const columns = [
    {
      headerName: 'Wtx Part #',
      field: 'wtxPartNumber',
      flex: 0.25
    },
    {
      headerName: 'TI Part #',
      field: 'tiPartNumber',
      flex: 0.25
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 0.25
    },
    {
      headerName: 'Last Update',
      field: 'lastUpdate',
      flex: 0.25
    },
    {
      headerName: 'Minimum Order',
      field: 'minimumOrderQuantity',
      type: 'number',
      flex: 0.25
    },
    {
      headerName: 'Limit',
      field: 'limit',
      type: 'number',
      flex: 0.25
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      type: 'number',
      flex: 0.25,
      renderCell: u => {
        return (
          <div>
            {u.row.quantity} {increasedFromZero(u.row) ? <NorthOutlined /> : null}
          </div>
        )
      }
    },
    {
      headerName: 'Part Count',
      field: 'standardPackQuantity',
      type: 'number',
      flex: 0.25
    }
  ]

  const increasedFromZero = part => {
    let previousQty = part.previousQuantity ? part.previousQuantity : 0
    let qty = part.quantity ? part.quantity : 0
    return previousQty === 0 && qty > 0
  }

  const refresh = async newPart => {
    refetchParts()
    if (newPart) setSelectedPart(newPart)
  }

  const deleteParts = async () => {
    let ids = selectedRows.map(part => part.id)
    await InventoryApi.deleteTrackedParts(instance, accounts, ids)

    refetchParts()
  }

  const addToCart = async () => {
    let cartItems = selectedRows.map(p => {
      let cartItem = {
        tiPartNumber: p.tiPartNumber,
        requestedQty: p.minimumOrderQuantity
      }
      return cartItem
    })

    await CartApi.addCartItems(instance, accounts, cartItems)

    let path = 'cart'
    history.push(`/${path}`)
  }

  if (partsLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (partsError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={partsError} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <AddPartModal
        open={addPartModalOpen}
        onClose={() => setAddPartModalOpen(false)}
        styles={props.styles}
        refresh={refresh}
        existingParts={parts}
      />
      <ViewPartModal
        open={viewPartModalOpen}
        onClose={() => setViewPartModalOpen(false)}
        styles={props.styles}
        part={selectedPart}
        refresh={refresh}
      />
      <AddSubscriptionModal
        open={addSubscriptionModalOpen}
        onClose={() => setAddSubscriptionModalOpen(false)}
        styles={props.styles}
        parts={selectedRows}
        refresh={refresh}
      />

      <PersistantFilterDiv
        resetFilter={() => setFilter(DEFAULT_PARTS_FILTER)}
        drawer={<PartFilterDrawer filter={filter} setFilter={setFilter} />}
        page={
          <div style={{ display: 'flex' }}>
            <PageNavbar></PageNavbar>
            <div
              style={{
                width: '100%',
                marginLeft: '24px'
              }}>
              <RegularButton onClick={e => setAddPartModalOpen(true)}>{'Add Part'}</RegularButton>
              <RegularButton
                style={{ marginLeft: 15 }}
                disabled={selectedRows.length === 0}
                onClick={e => {
                  addToCart()
                }}>
                {'Add to Cart'}
              </RegularButton>
              <RegularButton
                style={{ marginLeft: 15 }}
                disabled={selectedRows.length === 0}
                onClick={e => {
                  setAddSubscriptionModalOpen(true)
                }}>
                {'Add Notification'}
              </RegularButton>
              <RegularButton
                style={{ marginLeft: 15 }}
                disabled={selectedRows.length === 0}
                onClick={e => {
                  deleteParts()
                }}>
                {'Remove Parts'}
              </RegularButton>
              <div style={{ marginTop: '15px' }}>
                <CustomDataGrid
                  rows={filterParts(filter, parts).map((p, i) => {
                    return { ...p, key: i }
                  })}
                  loading={partsLoading}
                  columns={[...columns]}
                  onRowClick={grid => {
                    setSelectedPart(grid.row)
                    setViewPartModalOpen(true)
                  }}
                  checkboxSelection
                  onColumnHeaderClick={e =>
                    e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : parts) : null
                  }
                  onSelectionModelChange={ids => {
                    const selectedIDs = new Set(ids)
                    const selectedRows = parts.filter(row => selectedIDs.has(row.id))
                    setSelectedRows(selectedRows)
                  }}
                  cursor='pointer'
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  )
}
