import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import PageNavbar from '../controls/PageNavbar'
import { useQuery } from '@tanstack/react-query'
import SubscriptionApi from '../../api/SubscriptionApi'
import InventoryApi from '../../api/InventoryApi'
import { CustomDataGrid, CenteredDiv, ErrorMessage, RegularButton } from '@wavetronix/common-components'
import { IconButton, Tooltip } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import ManageEmailModal from '../modals/ManageEmailModal'

export default function NotificationsPage(props) {
  const { instance, accounts } = useMsal()
  const [selectedRows, setSelectedRows] = useState([])
  const [manageEmailModalOpen, setManageEmailModalOpen] = useState(false)
  const {
    data: subscriptions,
    isLoading: subscriptionsLoading,
    error: subscriptionsError,
    refetch: refetchSubscriptions
  } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: async () => {
      let subscriptions = await SubscriptionApi.getInventorySubscriptions(instance, accounts)
      console.log(JSON.stringify(subscriptions))
      return subscriptions
    }
  })

  const {
    data: allParts,
    isLoading: allPartsLoading,
    error: allPartsError
  } = useQuery({
    queryKey: ['allParts'],
    queryFn: async () => {
      let allParts = await InventoryApi.getTrackedParts(instance, accounts)
      return allParts
    }
  })

  const deleteNotification = async partNumber => {
    await SubscriptionApi.deleteInventorySubscription(instance, accounts, partNumber).catch(error => {})

    refetchSubscriptions()
  }

  const getWtxPartNumber = tiPartNumber => {
    console.log(tiPartNumber)
    let filteredParts = allParts.filter(p => p.tiPartNumber === tiPartNumber)
    console.log(JSON.stringify(filteredParts))
    let number = filteredParts.length > 0 ? filteredParts[0].wtxPartNumber : ''
    console.log(number)
    return number
  }

  const columns = [
    {
      headerName: 'Wtx Part #',
      field: 'wtxPartNumber',
      flex: 0.25,
      renderCell: u => {
        return <span>{getWtxPartNumber(u.row.tiPartNumber)}</span>
      }
    },
    {
      headerName: 'TI Part #',
      field: 'tiPartNumber',
      flex: 0.25
    },
    {
      headerName: 'Created',
      field: 'creationTimestamp',
      flex: 0.25
    },
    {
      headerName: 'Last Update',
      field: 'lastUpdatedTimestamp',
      flex: 0.25
    },
    {
      headerName: 'Quantity',
      field: 'requestedQty',
      type: 'number',
      editable: true,
      flex: 0.25
    },
    {
      headerName: 'Expiration Date',
      field: 'expirationDate',
      type: 'string',
      editable: true,
      flex: 0.25
    },
    {
      headerName: '',
      field: 'x',
      flex: 0.25,
      renderCell: u => {
        return (
          <div>
            <Tooltip title='Remove Notification' style={{ margin: '10px 15px 0px 60px' }}>
              <IconButton
                onClick={async e => {
                  await deleteNotification(u.row.tiPartNumber)
                }}
                color='primary'
                size='large'>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  if (subscriptionsLoading || allPartsLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (subscriptionsError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={subscriptionsError} />
      </CenteredDiv>
    )
  }

  if (allPartsError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={allPartsError} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <ManageEmailModal open={manageEmailModalOpen} onClose={() => setManageEmailModalOpen(false)} styles={props.styles} />
      <div>
        <div style={{ display: 'flex' }}>
          <PageNavbar></PageNavbar>
          <div
            style={{
              width: '100%',
              overflow: 'auto',
              margin: '0px 40px 40px 40px'
            }}>
            <RegularButton onClick={e => setManageEmailModalOpen(true)}>{'Manage Email'}</RegularButton>
            {!subscriptions || subscriptions.length === 0 ? (
              <div>{'There are no notifications setup'}</div>
            ) : (
              <div style={{ marginTop: '15px' }}>
                <CustomDataGrid
                  rows={subscriptions.map((p, i) => {
                    return { ...p, id: i }
                  })}
                  loading={subscriptionsLoading}
                  columns={[...columns]}
                  //checkboxSelection
                  onColumnHeaderClick={e =>
                    e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : subscriptions) : null
                  }
                  onSelectionModelChange={ids => {
                    const selectedIDs = new Set(ids)
                    const selectedRows = subscriptions.filter(row => selectedIDs.has(row.id))
                    setSelectedRows(selectedRows)
                  }}
                  cursor='pointer'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
