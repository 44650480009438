import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

function handleError(error, name) {
  console.log(`${name} failed!`)
  if (error.response) {
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
    throw new Error(`Unexpected Error: ${error.response.status}`)
  } else if (error.request) {
    console.log(`${error.response.status}: No Response!`)
    console.log(error.request)
    throw new Error(`${error.response.status}: No Response from server`)
  } else {
    console.log('Other Error!')
    console.log(error.toString())
    throw new Error(`${error.response.status}: Unexpected Error`)
  }
}

class SubscriptionApi {
  addInventorySubscription = async (msalInstance, accounts, subscriptionRequest) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/subscriptions/inventory/`, subscriptionRequest, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        timeout: 300000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Add inventory subscription')
      })
  }

  addInventorySubscriptions = async (msalInstance, accounts, subscriptionRequests) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/subscriptions/inventory/`, subscriptionRequests, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        timeout: 300000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Add inventory subscriptions')
      })
  }

  deleteInventorySubscription = async (msalInstance, accounts, partNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.tiServiceURL}/subscriptions/inventory?partNumber=${encodeURIComponent(partNumber)}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Delete inventory subscription')
      })
  }

  deleteInventorySubscriptions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.tiServiceURL}/subscriptions/inventory/all`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Delete inventory subscriptions')
      })
  }

  getInventorySubscription = async (msalInstance, accounts, partNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/subscriptions/inventory?partNumber=${encodeURIComponent(partNumber)}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get inventory subscription')
      })
  }

  getInventorySubscriptions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/subscriptions/inventory/all`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get inventory subscriptions')
      })
  }

  getEmails = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/subscriptions/inventory/email`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get inventory subscription emails')
      })
  }

  addEmail = async (msalInstance, accounts, email) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/subscriptions/inventory/email`, email, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Add inventory subscription email')
      })
  }

  deleteEmail = async (msalInstance, accounts, email) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.tiServiceURL}/subscriptions/inventory/email`, email, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Delete inventory subscription email')
      })
  }

  test = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/subscriptions/inventory/test`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Test subscription')
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new SubscriptionApi()
  }
  return instance
}

export default getInstance()
