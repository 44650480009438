export default function parseFileName(name) {
  if (name) {
    var fileNameType = name.split('.')
    if (fileNameType.length === 1 || (fileNameType.length === 2 && fileNameType[0] === '')) {
      return name
    } else {
      return fileNameType[0]
    }
  }
}

export const containsSubstring = (original, substring) => {
  if (original && substring) {
    if (!String.prototype.includes) {
      return original.toLowerCase().indexOf(substring.toLowerCase()) !== -1
    } else {
      return original.toLowerCase().includes(substring.toLowerCase())
    }
  } else {
    return false
  }
}
