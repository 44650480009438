import * as React from 'react'
import { Card, CardContent, TextField, IconButton, Tooltip } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import DialogContent from '@mui/material/DialogContent'
import { RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import SubscriptionApi from '../../api/SubscriptionApi'

export default function ManageEmailModal(props) {
  const { instance, accounts } = useMsal()
  const [isBusy, setIsBusy] = useState(false)
  const [updatedEmails, setUpdatedEmails] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const { data: emails, isLoading: emailsLoading } = useQuery({
    queryKey: ['emails'],
    queryFn: async () => {
      let emails = await SubscriptionApi.getEmails(instance, accounts)
      return emails
    }
  })

  useEffect(() => {
    if (emails) {
      if (emails.length === 0) {
        setUpdatedEmails([''])
      } else {
        setUpdatedEmails(emails)
      }
    }
  }, [emails, setUpdatedEmails])

  function onClose() {
    if (emails) {
      if (emails.length === 0) {
        setUpdatedEmails([''])
      } else {
        setUpdatedEmails(emails)
      }
    }
    props.onClose()
  }

  function setEmail(e, i) {
    console.log(`${JSON.stringify(e)} - ${i}`)
    let newEmailList = [...updatedEmails]
    newEmailList[i] = e
    setUpdatedEmails(newEmailList)
  }

  function addEmptyEmail(e) {
    let newEmailList = [...updatedEmails, '']
    setUpdatedEmails(newEmailList)
  }

  function removeEmail(i) {
    let newEmailList = [...updatedEmails]
    console.log(JSON.stringify(newEmailList))
    newEmailList.splice(i, 1)
    console.log(i)
    console.log(JSON.stringify(newEmailList))
    setUpdatedEmails(newEmailList)
  }

  const onSave = async () => {
    let newEmailList = [...updatedEmails]
    newEmailList = newEmailList.filter(e => e && e !== '')

    await SubscriptionApi.addEmail(instance, accounts, newEmailList).catch(err =>
      enqueueSnackbar('Saving email addresses failed', SnackbarVariants.ERROR)
    )

    onClose()
  }

  //console.log('ViewPartModal')
  if (emailsLoading) {
    return <div></div>
  }

  return (
    <SlideUpDialog
      maxWidth='sm'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{'Manage Emails'}</h3>}
      actions={
        <>
          <RegularButton autoFocus onClick={onSave} disabled={isBusy}>
            Save
          </RegularButton>
        </>
      }>
      <DialogContent>
        <Card style={{ minWidth: 500 }}>
          <CardContent>
            {updatedEmails.map((e, i) => (
              <div key={i}>
                <TextField
                  disabled={isBusy}
                  value={e}
                  label={`Email #${i + 1}`}
                  variant='outlined'
                  style={{ marginTop: 15, width: '60%' }}
                  onChange={e => setEmail(e.target.value, i)}
                />
                <Tooltip title='Remove email' style={{ margin: '10px 15px 0px 60px' }}>
                  <IconButton
                    onClick={async e => {
                      setIsBusy(true)
                      await removeEmail(i)
                      setIsBusy(false)
                    }}
                    color='primary'
                    size='large'>
                    <DeleteOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
            <div>
              <RegularButton autoFocus onClick={addEmptyEmail} disabled={isBusy} style={{ marginTop: 15 }}>
                New
              </RegularButton>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </SlideUpDialog>
  )
}
