import { PrimaryButton } from '@wavetronix/common-components'
import React from 'react'
import { Tooltip } from '@mui/material'

// prompt
// handleFile
// disabled
// style
// fileTypes
// setIsUploading
const FileUploader = React.forwardRef((props, ref) => {
  const hiddenFileInput = React.useRef(null)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const fileloaded = e => {
    const fileContents = e.target.result
    props.handleFile(fileContents)
  }

  const handleChange = event => {
    if (event.target.files.count === 0) return
    const file = event.target.files[0]
    console.log('loading file')
    props.setIsUploading(true)
    const reader = new FileReader()
    reader.onload = fileloaded
    reader.readAsText(file)
  }

  return (
    <div style={props.style}>
      <Tooltip title={props.tooltip}>
        <PrimaryButton style={{ width: '100%', marginBottom: 15 }} onClick={handleClick} disabled={props.disabled}>
          {props.prompt}
        </PrimaryButton>
      </Tooltip>
      <input
        type='file'
        id='file'
        ref={hiddenFileInput}
        accept={props.fileTypes}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  )
})

export default FileUploader
