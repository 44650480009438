import { TextField } from '@mui/material'
import { containsSubstring } from '../../utils/stringUtils'

export const DEFAULT_PARTS_FILTER = {
  wtxPartNumber: '',
  tiPartNumber: '',
  description: ''
}

export const filterParts = (filter, parts) => {
  return parts
    ? parts.filter(
        u =>
          //TODO: Fix for parts
          (containsSubstring(u.wtxPartNumber, filter.wtxPartNumber) || filter.wtxPartNumber === '') &&
          (containsSubstring(u.tiPartNumber, filter.tiPartNumber) || filter.tiPartNumber === '') &&
          (containsSubstring(u.description, filter.description) || filter.description === '')
      )
    : []
}

export default function PartsFilterDrawer({ filter, setFilter }) {
  return (
    <>
      <TextField
        style={{ width: '100%' }}
        label={'Wtx Part Number'}
        size='small'
        value={filter.wtxPartNumber}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, wtxPartNumber: e.target.value }))}
      />
      <TextField
        style={{ width: '100%', marginTop: 15 }}
        label={'TI Part Number'}
        size='small'
        value={filter.tiPartNumber}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, tiPartNumber: e.target.value }))}
      />
      <TextField
        style={{ width: '100%', marginTop: 15 }}
        label={'Description'}
        size='small'
        value={filter.description}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, description: e.target.value }))}
      />
    </>
  )
}
