import React from 'react'
import PageNavbar from '../controls/PageNavbar'

export default function OrdersPage(props) {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <PageNavbar></PageNavbar>
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            margin: '0px 40px 40px 40px'
          }}>
          <div>{'There are no orders yet'}</div>
        </div>
      </div>
    </div>
  )
}
