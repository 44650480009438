import { acquireAccessToken, MenuAppBar, parseJwt, RoleCheck } from '@wavetronix/common-components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { env } from '../index.js'
import { Redirect } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import PartsPage from './pages/PartsPage'
import CartPage from './pages/CartPage'
import OrdersPage from './pages/OrdersPage'
import NotificationsPage from './pages/NotificationsPage'
import version from '../env/version.json'
const drawerWidth = 300

const classes = {
  paper: {
    width: drawerWidth
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  },
  pageHeader: {
    marginLeft: '24px',
    marginTop: '30px',
    fontSize: '24px',
    flex: 1,
    display: 'inline',
    justifyContent: 'left'
  },
  tableRow: {
    cursor: 'pointer'
  }
}

export const URL_MAP = {
  parts: 0,
  notifications: 1,
  orders: 2,
  cart: 3,
  '': 0
}

function MainPage() {
  const { instance, accounts } = useMsal()
  const [uploadToken, setUploadToken] = useState('')
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      //console.log('resetting token')
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  return (
    <div className='content'>
      <MenuAppBar env={env} appHeader='Texas Instruments Part Tracker' />
      <RoleCheck allowedRoles={['TI.com']} env={env}>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <Redirect to='parts' />
            </Route>
            <Route
              path='/parts'
              exact
              render={() => (
                <PartsPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    console.log(s)
                    setState(s)
                  }}
                  showFullList={true}
                />
              )}
            />
            <Route path='/version' exact render={() => <div>{version.version}</div>} />
            <Route path='/notifications' exact render={() => <NotificationsPage styles={classes} />} />
            <Route path='/orders' exact render={() => <OrdersPage styles={classes} />} />
            <Route
              path='/cart'
              exact
              render={() => (
                <CartPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                  showFullList={true}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      </RoleCheck>
    </div>
  )
}
export default MainPage
