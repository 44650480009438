import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

function handleError(error, name) {
  console.log(`${name} failed!`)
  if (error.response) {
    console.log(error.response.data)
    console.log(error.response.headers)
    if (error.response.status) {
      console.log(error.response.status)
      throw new Error(`Unexpected Error: ${error.response.status}`)
    } else {
      throw new Error(`Unexpected Error`)
    }
  } else if (error.request) {
    console.log(error.request)
    console.log(`No Response!`)
    throw new Error(`No Response from server`)
  } else {
    console.log('Other Error!')
    console.log(error.toString())
    throw new Error(`Unexpected Error`)
  }
}

class CartApi {
  addCartItems = async (msalInstance, accounts, cartItem) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/cart`, cartItem, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        timeout: 300000
      })
      .catch(error => {
        handleError(error, 'Add Part to Cart')
      })
  }

  deleteCartItem = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.tiServiceURL}/cart/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Delete cart item')
      })
  }

  getCart = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/cart`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get Cart')
      })
  }

  purchase = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/cart/purchase`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Purchase')
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new CartApi()
  }
  return instance
}

export default getInstance()
