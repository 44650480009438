import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

function handleError(error, name) {
  console.log(`${name} failed!`)
  if (error.response) {
    console.log(error.response.data)
    console.log(error.response.headers)
    if (error.response.status) {
      console.log(error.response.status)
      throw new Error(`Unexpected Error: ${error.response.status}`)
    } else {
      throw new Error(`Unexpected Error`)
    }
  } else if (error.request) {
    console.log(error.request)
    console.log(`No Response!`)
    throw new Error(`No Response from server`)
  } else {
    console.log('Other Error!')
    console.log(error.toString())
    throw new Error(`Unexpected Error`)
  }
}

class InventoryApi {
  getCatalog = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/inventory/catalog`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 300000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Catalog Failed!')
        handleError(error, 'Get Catalog')
      })
  }

  refreshCatalog = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/inventory/catalog`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Refresh Catalog')
      })
  }

  getPartInfo = async (msalInstance, accounts, partNumber) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/inventory/part?partNumber=${encodeURIComponent(partNumber)}`, {
        headers: { Authorization: `Bearer ${token}`, ContentType: 'application/json' },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get Part Info')
      })
  }

  lookupParts = async (msalInstance, accounts, partLookup) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/inventory/lookup`, partLookup, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 120000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get Part Info')
      })
  }

  getTrackedParts = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/inventory/tracked`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get Tracked Parts')
      })
  }

  getTrackedPart = async (msalInstance, accounts, id, refresh) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let query = refresh === true ? '?refresh=true' : ''
    return await axios
      .get(`${env.urls.tiServiceURL}/inventory/tracked/${id}${query}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Get Tracked Part')
      })
  }

  addTrackedPart = async (msalInstance, accounts, part) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.tiServiceURL}/inventory/tracked`, part, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Add Tracked Part')
      })
  }

  updateTrackedPart = async (msalInstance, accounts, part) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${env.urls.tiServiceURL}/inventory/tracked`, part, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Update Tracked Part')
      })
  }

  deleteTrackedPart = async (msalInstance, accounts, id) => {
    console.log('deleteTrackedPart')
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.tiServiceURL}/inventory/tracked/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Delete Tracked Part')
      })
  }

  deleteTrackedParts = async (msalInstance, accounts, ids) => {
    console.log('deleteTrackedPart')
    let token = await acquireAccessToken(msalInstance, accounts, env)
    console.log(JSON.stringify(ids))

    return await axios
      .post(`${env.urls.tiServiceURL}/inventory/tracked/delete`, ids, {
        headers: { Authorization: `Bearer ${token}`, ContentType: 'application/json' },
        timeout: 30000
      })
      .catch(error => {
        handleError(error, 'Delete Tracked Parts')
      })
  }

  test = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.tiServiceURL}/inventory/test`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        handleError(error, 'Test')
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new InventoryApi()
  }
  return instance
}

export default getInstance()
