import * as React from 'react'
import { Card, CardContent, Tooltip, IconButton } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import { CenteredDiv, SnackbarVariants, CustomDataGrid, PrimaryButton, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { TextField } from '@mui/material'
import InventoryApi from '../../api/InventoryApi'
import PartView from '../controls/PartView'
import FileUploader from '../controls/FileUploader'

const classes = {
  paper: {
    position: 'absolute',
    width: '50%',
    height: '40%',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  outside: {
    backgroundColor: 'white'
  },
  fileMetadata: {
    width: '80%',
    marginTop: '15px'
  }
}

export default function AddPartModal(props) {
  const { instance, accounts } = useMsal()
  const [isBusy, setIsBusy] = useState(false)
  const [partNumber, setPartNumber] = useState('')
  const [part, setPart] = useState(null)
  const [parts, setParts] = useState([])
  const [noValidParts, setNoValidParts] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (parts) {
      let notFoundcount = parts.filter(p => !p.description).length
      let noParts = parts.length === 0
      let allNotFound = notFoundcount === parts.length || noParts

      console.log(`notFoundcount: ${notFoundcount}`)
      console.log(`parts.length: ${parts.length}`)
      console.log(`allNotfound: ${allNotFound}`)
      setNoValidParts(allNotFound)
    }
  }, [parts, setNoValidParts])

  function onClose() {
    if (part) {
      setPart(null)
      return
    }
    setPart(null)
    setPartNumber('')
    setParts([])
    props.refresh()
    props.onClose()
  }

  const lookupPart = async () => {
    setIsBusy(true)
    let newPart = await InventoryApi.getPartInfo(instance, accounts, partNumber).catch(e => {
      enqueueSnackbar(`Part '${partNumber}' not found`, SnackbarVariants.ERROR)
      return null
    })
    let filteredParts = parts.filter(p => p.tiPartNumber !== partNumber)
    if (newPart) {
      setParts([newPart, ...filteredParts])
    } else {
      let badPart = { tiPartNumber: partNumber }
      setParts([badPart, ...filteredParts])
    }
    setIsBusy(false)
  }

  const retryLookup = async partNum => {
    setIsBusy(true)
    console.log(`retryLookup: ${partNum}`)
    let newPart = await InventoryApi.getPartInfo(instance, accounts, partNum).catch(e => {
      enqueueSnackbar(`Part '${partNum}' not found`, SnackbarVariants.ERROR)
      return null
    })
    console.log(`retryLookup: after part lookup - ${JSON.stringify(newPart)}`)
    let replacementPart = newPart ? newPart : { tiPartNumber: partNum }
    console.log(`replacementPart: ${JSON.stringify(replacementPart)}`)
    let filteredParts = parts.map(p => (p.tiPartNumber === partNum ? replacementPart : p))
    setParts([...filteredParts])

    setIsBusy(false)
  }

  const parseWtxPartNumber = line => {
    if (!line) {
      console.log('line is null or undefined')
      return ''
    }
    // First column is Wavetronix item number
    var commaIndex = line.indexOf(',')
    if (commaIndex < 0) {
      console.log(`line '${line}' contains no comma`)
      return ''
    }
    return line.substring(0, commaIndex).trim()
  }

  const parseTiPartNumber = line => {
    if (!line) {
      console.log('line is null or undefined')
      return ''
    }
    // First column is Wavetronix item number
    var tiIndex = line.indexOf('Texas Instruments')
    if (tiIndex < 0) {
      console.log(`line '${line}' does not contain 'Texas Instruments'`)
      return ''
    }
    let texasIntrumentsSection = line.substring(tiIndex)
    let verticleBarIndex = texasIntrumentsSection.indexOf('|')
    if (verticleBarIndex < 0) {
      console.log(`line '${line}' does not contain '|' in the Texas Instruments section`)
      return ''
    }
    let tiPartNumberSection = texasIntrumentsSection.substring(verticleBarIndex + 1)
    let secondVerticleBarIndex = tiPartNumberSection.indexOf('|')
    if (secondVerticleBarIndex < 0) {
      console.log(`line '${line}' does not contain a second '|' in the Texas Instruments section`)
      return ''
    }
    return tiPartNumberSection.substring(0, secondVerticleBarIndex - 1).trim()
  }

  const setWtxPartNumber = (part, lookup) => {
    if (!part.wtxPartNumber || part.wtxPartNumber === '') {
      let lookupItems = lookup.filter(p => p.tiPartNumber === part.tiPartNumber)
      if (lookupItems && lookupItems.length > 0) {
        let lookupItem = lookupItems[0]
        let newPart = { ...part, wtxPartNumber: lookupItem.wtxPartNumber }
        return newPart
      }
    }

    return part
  }

  const handleFile = async fileContent => {
    // unix line endings
    let text = fileContent.replace(/\r\n/g, '\n')
    // split into lines
    let lines = text.split('\n')
    // Remove lines that don't have Texas Instruments part numbers (like a header line)
    let existingParts = props.existingParts ? props.existingParts : []
    let importedParts = lines
      .map(l => {
        return { wtxPartNumber: parseWtxPartNumber(l), tiPartNumber: parseTiPartNumber(l) }
      })
      .filter(p => p.wtxPartNumber !== '' && p.tiPartNumber !== '')
    let duplicates = importedParts.filter(p => existingParts.filter(e => e.tiPartNumber === p.tiPartNumber).length > 0)
    if (duplicates && duplicates.length > 0) {
      enqueueSnackbar(`${duplicates.length} parts are already tracked`, SnackbarVariants.SUCCESS)
    }
    importedParts = importedParts.filter(p => existingParts.filter(e => e.tiPartNumber === p.tiPartNumber).length === 0)

    enqueueSnackbar(`${importedParts.length} parts imported from file`, SnackbarVariants.SUCCESS)

    let partNumbers = importedParts.map(p => p.tiPartNumber)
    let partLookup = {
      partNumbers: partNumbers
    }
    console.log(JSON.stringify(importedParts))

    let lookedUpParts = await InventoryApi.lookupParts(instance, accounts, partLookup).catch(e => {
      enqueueSnackbar(`Parts lookup failed`, SnackbarVariants.ERROR)
      return null
    })
    // let lookedUpParts = importedParts
    if (lookedUpParts) {
      lookedUpParts = lookedUpParts.map(p => (p.description ? p : { tiPartNumber: p.tiPartNumber }))
      let newPartNumbers = lookedUpParts.map(p => p.tiPartNumber)
      let filteredParts = parts.filter(p => !newPartNumbers.includes(p.tiPartNumber))

      let allParts = [...filteredParts, ...lookedUpParts]
      let updatedParts = allParts.map(p => setWtxPartNumber(p, importedParts))
      setParts(updatedParts)
      //console.log(JSON.stringify(updatedParts))
    }
    setIsBusy(false)
  }

  const createTrackedParts = async () => {
    try {
      await Promise.all(parts.filter(p => p.description).map(p => InventoryApi.addTrackedPart(instance, accounts, p)))
    } catch (e) {}
    setIsBusy(false)
    onClose()
  }

  const columns = [
    {
      headerName: 'Wtx Part #',
      field: 'wtxPartNumber',
      flex: 0.25
    },
    {
      headerName: 'TI Part #',
      field: 'tiPartNumber',
      flex: 0.25
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 0.25,
      renderCell: u => {
        return (
          <div>
            {u.row.description ? u.row.description : 'Part not found'}
            {u.row.description ? null : (
              <Tooltip title='Try again'>
                <IconButton
                  onClick={async e => {
                    e.preventDefault()
                    e.stopPropagation()
                    await retryLookup(u.row.tiPartNumber)
                  }}
                  color='primary'
                  size='large'>
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )
      }
    },
    {
      headerName: 'Minimum Order',
      field: 'minimumOrderQuantity',
      type: 'number',
      flex: 0.25
    },
    {
      headerName: 'Limit',
      field: 'limit',
      type: 'number',
      flex: 0.25
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      type: 'number',
      flex: 0.25
    },
    {
      headerName: 'Part Count',
      field: 'standardPackQuantity',
      type: 'number',
      flex: 0.25
    }
  ]

  return (
    <SlideUpDialog
      maxWidth='xl'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{'Parts'}</h3>}
      actions={
        <>
          {!part ? (
            <PrimaryButton
              style={{ marginRight: 15 }}
              disabled={isBusy || noValidParts}
              onClick={async () => {
                setIsBusy(true)
                await createTrackedParts()
              }}>
              &nbsp;{'Add Parts'}
            </PrimaryButton>
          ) : (
            <div></div>
          )}
        </>
      }>
      <DialogContent>
        <Card style={{ minWidth: 700 }}>
          <CardContent>
            {!part ? (
              <div>
                <CenteredDiv>
                  <TextField
                    disabled={isBusy}
                    label={'Part Number'}
                    variant='outlined'
                    style={{ marginTop: 15, width: '60%' }}
                    onChange={e => setPartNumber(e.target.value)}
                  />
                  <Tooltip title={'Lookup a single part number'} style={{ marginTop: '10px', marginLeft: '15px' }}>
                    <PrimaryButton autoFocus onClick={lookupPart} disabled={isBusy}>
                      &nbsp;{'Lookup Part'}
                    </PrimaryButton>
                  </Tooltip>
                </CenteredDiv>
                <CenteredDiv>
                  <FileUploader
                    disabled={isBusy}
                    style={classes.fileMetadata}
                    handleFile={handleFile}
                    fileTypes='text/csv'
                    prompt={'Load from file'}
                    setIsUploading={isUploading => {
                      setIsBusy(isUploading)
                    }}
                    tooltip='Load part numbers from a text file where each part number is on its own line'
                  />
                </CenteredDiv>

                <CustomDataGrid
                  rows={parts.map((p, i) => {
                    return { ...p, key: i, id: i }
                  })}
                  loading={isBusy}
                  columns={[...columns]}
                  onRowClick={grid => {
                    setPart(grid.row)
                  }}
                  cursor='pointer'
                />
              </div>
            ) : (
              <div></div>
            )}
            <CenteredDiv>
              <div>{part ? <PartView part={part}></PartView> : <div></div>}</div>
            </CenteredDiv>
          </CardContent>
        </Card>
      </DialogContent>
    </SlideUpDialog>
  )
}
