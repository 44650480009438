import { Tab, Tabs } from '@mui/material'
import { useHistory } from 'react-router'
import '../../index.css'
import { WtxColors } from '@wavetronix/common-components'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '210px'
  }
}

const URL_MAP = {
  parts: 0,
  notifications: 1,
  orders: 2,
  cart: 3,
  '': 0
}

export default function PageNavbar(props) {
  const history = useHistory()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      history.push(`/${path}`)
    }
  }

  let path = window.location.pathname.substring(1)
  let currentTab = URL_MAP[path]

  return (
    <Tabs
      value={currentTab}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}>
      <Tab sx={classes.tab} label='Parts' onClick={() => onChange('parts')} />
      <Tab sx={classes.tab} label='Notifications' onClick={() => onChange('notifications')} />
      <Tab sx={classes.tab} label='Orders' onClick={() => onChange('orders')} />
      <Tab sx={classes.tab} label='Cart' onClick={() => onChange('cart')} />
    </Tabs>
  )
}
