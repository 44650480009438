export default function PartView(props) {
  let properties = [
    { value: props.part.description, title: 'Description' },
    { value: props.part.lifeCycle, title: 'Life Cycle' },
    { value: props.part.genericPartNumber, title: 'Generic Part Number' },
    { value: props.part.buyNowUrl, title: 'Buy Now URL' },
    { value: props.part.quantity, title: 'Quantity' },
    { value: props.part.limit, title: 'Limit' },
    { value: props.part.minimumOrderQuantity, title: 'Minimum Order Quantity' },
    { value: props.part.standardPackQuantity, title: 'Standard Pack Quantity' },
    { value: props.part.pinCount, title: 'Pin Count' },
    { value: props.part.packageType, title: 'Package Type' },
    { value: props.part.packageCarrier, title: 'Package Carrier' },
    // { value: props.part.customReel.toString(), title: 'Custom Reel' },
    { value: props.part.exportControlClassificationNumber, title: 'Export Control' },
    { value: props.part.htsCode, title: 'HTS Code' }
  ]

  if (props.usePartNumber) {
    let properties2 = [{ value: props.part.tiPartNumber, title: 'Part number' }]
    for (let i = 0; i < properties.length; i++) {
      properties2.push(properties[i])
    }
    properties = properties2
  }
  return (
    <table>
      <tbody>
        {properties.map((p, i) => (
          <tr key={i}>
            <td className='propertyTitle'>{p.title}</td>
            <td className='propertyValue'>{p.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
