import * as React from 'react'
import { Card, CardContent } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { CenteredDiv, RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import InventoryApi from '../../api/InventoryApi'
import PartView from '../controls/PartView'

export default function ViewPartModal(props) {
  const { instance, accounts } = useMsal()
  const [isBusy, setIsBusy] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  function onClose() {
    props.onClose()
  }

  const refeshPart = async () => {
    setIsBusy(true)
    //console.log('refeshPart')
    //console.log(JSON.stringify(props.part))

    let updatedPart = await InventoryApi.getTrackedPart(instance, accounts, props.part.id, true).catch(e => {
      //console.log(JSON.stringify(e))
      enqueueSnackbar('Part not found', SnackbarVariants.ERROR)
    })
    //console.log(JSON.stringify(updatedPart))
    props.refresh(updatedPart)
    setIsBusy(false)
  }

  if (!props.part) {
    return <div></div>
  }

  //console.log('ViewPartModal')

  return (
    <SlideUpDialog
      maxWidth='md'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{'View Part'}</h3>}
      actions={
        <>
          <RegularButton autoFocus onClick={onClose} disabled={isBusy}>
            &nbsp;{'OK'}
          </RegularButton>
        </>
      }>
      <DialogContent>
        <Card style={{ minWidth: 700 }}>
          <CardContent>
            {props.part ? <div style={{ marginBottom: 20 }}>{`Last Updated ${props.part.lastUpdate}`}</div> : <div></div>}
            <CenteredDiv>
              <RegularButton autoFocus onClick={refeshPart} disabled={isBusy}>
                &nbsp;{'Refresh Part Data'}
              </RegularButton>
            </CenteredDiv>
            <CenteredDiv>
              <div style={{ marginTop: 20 }}>
                <PartView part={props.part} usePartNumber={true}></PartView>
              </div>
            </CenteredDiv>
          </CardContent>
        </Card>
      </DialogContent>
    </SlideUpDialog>
  )
}
